import React, { useState } from 'react';
import './App.css';

function App() {
  const [page, setPage] = useState('home');

  const navigateTo = (pageName) => {
    setPage(pageName);
  };

  return (
    <div>
        <h1>With just a few words, God brings salvation into your life!</h1>
        <nav>
          <button onClick={() => navigateTo('home')}>Home</button>
          <button onClick={() => navigateTo('Careers')}>A slice of life - career</button>

        </nav>
      <main>
        {page === 'home' && <Home />}
        {page === 'Careers' && <Careers />}

      </main>
    </div>
  );
}

function Home() {
  return (
    <div>
      <h1>Life is a gift on loan!</h1>
      <p> Greetings!<br/>
      
      <br/>
       I would like to share Biblical stories and truths that I believe will bless your life and enrich it! <br/>
       
       <br/>
       Let's start with one. <br/>
       
       <br/>There was this guy named Nicodemus, a religious leader, who came to see Jesus one night. He started off by saying, "Jesus, we know you're a teacher from God, because no one can do the things you do without God's help."

Jesus cut right to the point. He said, "You can't even see God's kingdom unless you're born again."

Nicodemus was puzzled. "How can anyone be born again when they're old? Do they have to go back into their mother's womb and be born again?"

Jesus clarified, "I'm talking about a spiritual birth, not a physical one. You need to be born of water and the Spirit. Flesh gives birth to flesh, but the Spirit gives birth to spirit. Don't be surprised that I say you must be born again."

He continued, "Just as Moses lifted up the snake in the wilderness, the Son of Man must be lifted up, so that everyone who believes in him may have eternal life. God loved all the people in the world so much that he gave his one and only Son, so that everyone who believes in him will not perish but have eternal life. God sent his Son to the people in the world not to judge the people, but to save all the people in the world through Jesus. Anyone who believes in Jesus is not judged, but anyone who does not believe is already judged, because they have not believed in the name of the one and only Son of God."

So, the bottom line is this: If you believe in Jesus, you have eternal life. If you don't, well, you're missing out.</p>
      
    </div>
  );
}


function Careers() {
  return (
    <div>
      <h3>Some thoughts on career, a slice of life</h3>
      <p> Greetings!<br/>
      
      <br/>
       We would like to share our thoughts on career and we believe these will bless your life and encourage you! <br/>
       
       <br/>
       Let's look at 3 things <br/>
       
       <br/>1.Meaning of the word 'Career'
       
       <br/>2.How God helped me in my Career?
       
       <br/>3.Some more considerations concerning the choice of a career
       <br/>

       <br/>1.Meaning of the word 'Career': One definition  - A job or profession that somebody has been trained for and does for a long time; the series of jobs that somebody has in a particular area of work. example - Sarah is considering a
        career in engineering.<br/>
      And another definition - the period of your life that you spend working. usage - She spent most of her career working in India.<br/>
       
       <br/>2.How God helped me in my Career? God helped me in choosing the Subjects of Study
through my loving Mother (Elective Mathematics in my S.S.L.C. (Standard XI) and through a caring Principal of a College in choosing Mathematics, Economics and Commerce in my P.U.C.
(Pre-University Course) taking into consideration my interest, aptitude and family circumstances and later in joining B.Com. Degree Course.With inputs a Professor gave in the final year of B.Com. course about various avenues available for higher education and job
opportunities available for B. Com. Graduates, I got interested in pursuing M.B. A. Course and tried to gather relevant additional information. I had an interest in Teaching and jobs were not so easy to come by at that time. Upon finishing M.B.A., I got the first job as an Assistant
Professor of Business Administration in a private aided college. About a year later I joined
a Group of Small Scale industrial undertakings. Two years later I again applied for and got Teaching Job in another private aided college. After three years I shifted to another private aided college where I continued to work for the next 30 years until retirement at age 58. Throughout my studies and Career, I prayed for and received God’s help in all respects including guidance, admissions and job openings in astonishing (miraculous) ways by the Grace of my Lord and Saviour Jesus Christ! One specific Word of Promise that God gave me and faithfully kept was, _ “If you, who are evil, know how to give good gifts to your children,
how much more will your Father Who is in heaven give good things to those who ask Him! (Matthew 7:7-11)<br/>

<br/>

3.Some more considerations concerning the choice of a career<br/>
<p style={{ textIndent: '20px' }}>
  1.One's interest in the field of work being considered<br/>
  <p style={{ textIndent: '20px' }}>
  2.Qualifications required for the successful performance in the job (by the potential employer) in terms of education, training, skills and experience
  </p>
   <p style={{ textIndent: '20px' }}>
  3.Qualifications the candidate possesses
  </p>
  <p style={{ textIndent: '20px' }}>
  4.The gap between 2 and 3 and how it may be bridged
  </p>
  <p style={{ textIndent: '20px' }}>
  5.The compensation package expected and offered
  </p>
  <p style={{ textIndent: '20px' }}>
  6.The potential for career progression - possible increases in pay and benefits, opportunities for promotion and transfers and training within the country and oversees.
  </p>
  <p style={{ textIndent: '20px' }}>
  And when a person is seeking his or her very first employment, it may be advisable to be more practical about his or her expectations, taking into consideration the prevailing condition in the job market in the specific industry, economy(country) and the world.
  </p>
  <p style={{ textIndent: '20px' }}>
  Above all considerations, One who believes in the ALL knowing and ALL powerful GOD can trust HIS goodness to provide him with what is best, because of HIS grace! After all the CREATOR knows that it is HIS WILL for everyone to work with his own hands to make a living and to provide for the needs of his wife and chhildren
  </p>
  </p>
       </p>
    </div>
  );
}



export default App;
